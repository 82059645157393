<template>
  <div
    class="test-method-matrix px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent
          class="py-8"
          title="In-Process Classification"
          size="large"
        />

        <p class="font-bold">
          This section of the Test Methods Matrix™ database outlines the
          purpose, key test parameters, and indicators (including pictures and
          videos) for each sensitivity and reactivity characterization test
          prescribed in the ET Users Group technical standard entitled
          <a
            style="color:#ef9a3d;"
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ETUG-Standard-ETUG-GS01-15.pdf?alt=media&token=8f0c42a1-7359-4473-82c4-7997887eca5e"
            >“ETUG-GS01-15: ETUG Standard for In-Process Hazard Classification
            of Explosives”</a
          >; this standard is referenced in NFPA 495 “Explosives Materials Code”
          2018 in Section A.5.3.1.
        </p>

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2">
            <h2 class="text-2xl font-bold">
              In-Process Decision Tree for
              <span style="color:green;">Substances</span> (1.1 or 1.3)
            </h2>
            <img src="@/assets/Figure-2-web-min.png" />
          </div>
          <div class="w-full md:w-1/2">
            <h2 class="text-2xl font-bold">
              In-Process Decision Tree for
              <span style="color:green;">Articles</span> (1.1, 1.2, 1.3 or 1.4)
            </h2>
            <img src="@/assets/Figure-3-web-min.png" />
          </div>
          <div class="w-full">
            <p class="font-bold">
              Please contribute to the Test Method Matrix by adding to the
              database by: (1) submitting comments at the bottom of each page,
              (2) submitting new content or modifications on the
              <!-- /content-submittal-form -->
              <router-link to="/contact-us" style="color:#ef9a3d;"
                >Content Submittal Form</router-link
              >, (3) sending us comments via the
              <router-link style="color:#ef9a3d;" to="/contact-us"
                >Contact Us</router-link
              >
              page or (4) giving us a call at 801-567-0456.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
